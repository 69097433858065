import { registerApplication, start, navigateToUrl } from "single-spa";

let d = document.documentElement;
let cw = d.clientWidth || 750;
d.style.fontSize = (16 / 1920) * cw + "px";

if (location.href === window.location.origin + "/") {
    if (sessionStorage.getItem("jwt") === null) {
        navigateToUrl("/login");
    } else {
        navigateToUrl("/dashboard");
    }
}

window.addEventListener(
    "orientationchange" in window ? "orientationchange" : "resize",
    (function () {
        function c() {
            let d = document.documentElement;
            let cw = d.clientWidth || 750;
            d.style.fontSize = (16 / 1920) * cw + "px";
        }
        c();
        return c;
    })(),
    false
);

registerApplication({
    name: "@aim-mf/navbar",
    app: () => System.import("@aim-mf/navbar"),
    activeWhen: [
        "/risk-management",
        "/dashboard",
        "/risk-edit",
        "/admin",
        "/control-self-assessment",
        "/task-management",
        "/alert-management",
        "/user-profile",
        "/entity",
        "/case-management",
    ],
});

registerApplication({
    name: "@aim-mf/user-profile",
    app: () => System.import("@aim-mf/user-profile"),
    activeWhen: ["/user-profile"],
});

// registerApplication({
//     name: "@aim-mf/landing-page",
//     app: () => System.import("@aim-mf/landing-page"),
//     activeWhen: [ "/marketing"],
// });

registerApplication({
    name: "@aim-mf/risk-management",
    app: () => System.import("@aim-mf/risk-management"),
    activeWhen: ["/risk-management"],
});

registerApplication({
    name: "@aim-mf/dashboard",
    app: () => System.import("@aim-mf/dashboard"),
    activeWhen: ["/dashboard"],
});

registerApplication({
    name: "@aim-mf/login",
    app: () => System.import("@aim-mf/login"),
    activeWhen: ["/login"],
});

registerApplication({
    name: "@aim-mf/risk-edit",
    app: () => System.import("@aim-mf/risk-edit"),
    activeWhen: ["/risk-edit"],
});

registerApplication({
    name: "@aim-mf/user-admin",
    app: () => System.import("@aim-mf/user-admin"),
    activeWhen: ["/admin"],
});

registerApplication({
    name: "@aim-mf/control-self-assessment",
    app: () => System.import("@aim-mf/control-self-assessment"),
    activeWhen: ["/control-self-assessment"],
});

registerApplication({
    name: "@aim-mf/task-management",
    app: () => System.import("@aim-mf/task-management"),
    activeWhen: ["/task-management"],
});

registerApplication({
    name: "@aim-mf/alert-management",
    app: () => System.import("@aim-mf/alert-management"),
    activeWhen: ["/alert-management"],
});

registerApplication({
    name: "@aim-mf/business-entity",
    app: () => System.import("@aim-mf/business-entity"),
    activeWhen: ["/entity"],
});

registerApplication({
    name: "@aim-mf/development-guide",
    app: () => System.import("@aim-mf/development-guide"),
    activeWhen: ["/development-guide"],
});

registerApplication({
    name: "@aim-mf/case-management",
    app: () => System.import("@aim-mf/case-management"),
    activeWhen: ["/case-management"],
});

start();
